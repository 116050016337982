import * as Sentry from "@sentry/nextjs";

import clientPackage from "@vienna/client/package.json";

Sentry.init({
  dsn: "https://de29d60fe58040ad9beedc0135d093eb@o1143996.ingest.sentry.io/6205308",
  release: `${clientPackage.version}-${process.env.ENVIRONMENT}`,
  environment: process.env.ENVIRONMENT === "prod" ? "production" : "development",
  // // Set tracesSampleRate to 1.0 to capture 100%
  // // of transactions for performance monitoring.
  // // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});
